<template>
  <div class="upload-file">
    <div class="container">
      <div class="item-group">
        <div class="label">选择文件（视频、录音、图片）</div>
        <el-upload :file-list="imgFileList" :action="uploadObj.action" list-type="picture-card"
          :headers="uploadObj.headers" :on-success="uploadSuccess">
          <i slot="default" class="el-icon-plus" />
          <div slot="file" slot-scope="{ file }">
            <el-image :src="file.url" :preview-src-list="[file.url]" />
          </div>
        </el-upload>
      </div>
      <div class="item-group">
        <div class="label">描述（选填）</div>
        <el-input v-model="form.remark" type="textarea" :autosize="{ minRows: 6, maxRows: 8 }" placeholder="请输入描述" />
      </div>
      <div class="item-group submit">
        <el-button plain>取消</el-button>
        <el-button type="primary" @click="submitForm">提交</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UploadFile',
  data() {
    return {
      dialogImageUrl: '',
      disabled: false,
      form: {},
      imgFileList: [],
      fileList: [],
      uploadObj: {
        action: '/common/upload',
        headers: {
          Authorization: ''
        }
      }
    }
  },
  computed: {
    _token() {
      return 'Bearer ' + this.$route.query.token
    },
    noticeId() {
      return this.$route.query.noticeId
    }
  },
  created() {
    this.uploadObj.headers.Authorization = this._token
    // console.log(this.$route.query)
    // axios.defaults.headers["Content-Type"] = "application/json;charset=utf-8"
    // // 创建axios实例
    // this.service = axios.create({
    //   // axios中请求配置有baseURL选项，表示请求URL公共部分
    //   baseURL: "/api", // 超时
    //   timeout: 10000,
    // })
    this.getModuleInfo()
  },
  methods: {
    handleRemove(file) {
    },
    uploadSuccess(data, index, lists, name) {
      this.fileList.push(data.data)
    },
    async getModuleInfo() {
      return await fetch(`${this.baseUrl}/archives/notice/${this.noticeId}`, {
        method: 'GET',
        headers: {
          Authorization: this._token
        }
      }).then(e => e.json())
    },
    async submitForm() {
      const {
        data: { dictId, archivesId, noticeId, jsonStr }
      } = await this.getModuleInfo()
      const jsonStrArr = jsonStr && jsonStr != '' ? JSON.parse(jsonStr) : []
      jsonStrArr.push({
        title: new Date().getTime() + '',
        imgFileList: this.fileList,
        remark: this.form.remark
      })
      const data = {
        dictId,
        archivesId,
        noticeId,
        jsonStr: JSON.stringify(jsonStrArr)
      }
      fetch(`${this.baseUrl}/archives/notice`, {
        method: 'PUT',
        headers: {
          Authorization: this._token,
          'Content-Type': 'application/json;charset=UTF-8'
        },
        mode: 'cors',
        body: JSON.stringify(data)
      })
        .then(e => e.json())
        .then(res => {
          if (res.code == 200) {
            alert(res.msg)
            this.form = {}
            this.imgFileList = []
            this.fileList = []
          }
        })
        .catch(e => console.log('Oops, error', e))
    }
  }
}
</script>
<style scoped>
.upload-file {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container {
  width: 85%;
}

.item-group {
  text-align: left;
  margin-bottom: 20px;
}

.label {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
}

.submit {
  text-align: center;
}
</style>
